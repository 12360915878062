<template>
  <div class="technologyPage">
    <!-- <h3 style="text-align: center; font-size: 22px;">{{title}}</h3> -->
    <div class="technologyPageCont" v-html="content"></div>
    <!-- <p>
      甘肃省建设投资（控股）集团有限公司（简称“甘肃建投”），是全省首批国有资本投资公司试点单位之一，肩负服务全省发展战略、优化国有资本布局、提升产业竞争力的职责。连续9年位列中国企业500强，2020年名列第294位，完成经济总量逾800亿元。
    </p>
    <p>
      近年来，甘肃建投积极践行新发展理念，服务国家发展战略、全省社会经济发展，引领行业发展，实践运用产融结合、产城融合发展模式，充分释放在投融资、项目运作、科技研发、技术创新等方面的显著优势。先后与甘肃省各市州签订战略合作协议，在区域合作中创新机制，投资建设了一大批省市重点项目，涉及城乡基础设施建设、建筑工业化、装备制造、黄河流域治理、绿色环保、建材矿山、文旅酒店等，牢固树立甘肃建投品牌形象，扩大影响力。国内紧盯京津冀、粤港澳、长三角、成渝、关中天水等城市圈建设，积极走出去，建安、地产业务已经拓展到了上海、深圳、天津、杭州、成都、重庆、西安、无锡等一、二线城市。“百郦”系列房地产开发项目，已形成良好的品牌效应。
    </p>
    <p>
      自1978年援建“多哥人民联盟之家”工程开始，甘肃建投先后在亚洲、欧洲、非洲、大洋洲、和美洲的40余个国家和地区，开展经援、工程承包、建材、技术和劳务合作、商贸、酒店旅游等业务，建成了一大批在国际上有较大影响和高质量的经援工程、承包工程。近年来抢抓“一带一路”建设及国家扶持甘肃发展的各种政策叠加机遇，除巩固传统的西、南部非洲市场外，又开辟了东非、东欧、南亚、东南亚、南美等新兴市场，成立了近20个驻外机构，是ENR全球最大250家国际承包商。
    </p> -->
  </div>
</template>
<script>
// import {brief} from '@/api/data/brief.js'
import { getArtInfoByChannel } from '@/api/common'
export default {
  props: {
    paramsName: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      title: '',
      content: ''
    }
  },
  mounted() {
    // this.title = brief[this.paramsName].title
    // this.content = brief[this.paramsName].content
    var _this = this
    this.$nextTick(() => {
      _this.getArtInfoByChannel()
    })
  },
  methods: {
    // 获取详情
    getArtInfoByChannel() {
      var _this = this

      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: _this.paramsName
      }
      getArtInfoByChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.content = data.content
        }
      })
    }
  }
}
</script>
<style lang="scss">
.technologyPageCont {
  p {
    line-height: 22px;
  }
}
@media (max-width: 992px) {
  .technologyPageCont img {
    max-width: 100% !important;
    height: auto !important;
  }
}
@media (max-width: 768px) {
  .technologyPageCont img {
    max-width: 100% !important;
    height: auto !important;
  }
  .technologyPageCont table {
    min-width: 180%;
  }
}
</style>