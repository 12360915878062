<template>
  <div class="businessUnit">
    <div class="ziHead">
      <img :src="indexImg" style="width: 100%; height: 100%" />
    </div>
    <div class="ziNav">
      <div class="navMain">
        <div class="navMainLeft">
          <span
            class="navMainInner"
            :class="{ active: active == item.entTChannelUuid }"
            v-for="(item, index) in navSubList"
            :key="index"
            @click="handleTab(item, index)"
          >
            {{ item.name }}
          </span>
        </div>

        <div class="navMainRight">
          <span>
            <router-link to="/"><i class="iconfont">&#xe689;</i> <span> &nbsp;> </span> </router-link>
          </span>
          <a href="/businessUnit/00200001" class="textTitle">{{ mathedTitle }} </a>
          <span> &nbsp;> {{ pathtitle }} </span>
        </div>
      </div>
    </div>
    <div class="ziBase">
      <div class="techologyBox">
        <div class="techologyWrap">
          <div class="fl techologyLeft">
            <div class="techologyLeftBox">
              <div class="techologyLeftHd">
                {{ techologyHd }}
              </div>
              <div class="techologyLeftLine"></div>
              <div class="techologyLeftWrap">
                <div class="techologySide">
                  <div class="techologySideInner" v-for="(item, index) in techologySide" :key="index">
                    <div
                      class="techologySideHd"
                      :class="{ active: viewSideSubTitle == item.entTChannelUuid, on: viewSideSub == item.entTChannelUuid}"
                      @click="handleSideHd(item)"
                    >
                      {{ item.name }} <span class="ico"><i class="el-icon-caret-right"></i></span>
                    </div>
                    <el-collapse-transition >
                      <div class="techologySideSub" v-show=" viewSideSub == item.entTChannelUuid">
                        <div
                          class="techologySideSubInner"
                          :class="{ active: viewSideSubInner == item1.entTChannelUuid }"
                          v-for="(item1, index1) in item.child"
                          :key="index1"
                          @click="handleSideSubInner(item1)"
                        >
                          <span class="dian"></span> <span class="txt">{{ item1.name }}</span>
                        </div>
                      </div>
                    </el-collapse-transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="fr techologyRight">
            <div class="techologyRightBox">
              <div class="techologyRightHd">
                <div class="techologyRightHdTit fl">{{ techologyRightHd }}</div>
                <div class="techologyRightHdTitInfo fl" v-if="isShow">&nbsp; > {{ techologyRightInfo }}</div>
                <div class="techologyRightHdTitInfo fl" v-if="isShow2">&nbsp; > {{ techologyRightInfo2 }}</div>
                <!-- <div class="techologyRightHdRight">
                  <span>
                    <router-link to="/homePage"><i class="iconfont">&#xe689;</i> <span> &nbsp;> </span> </router-link>
                  </span>
                  <span> <a href="/businessUnit/00200001" class="textTitle"> 科技创新</a> > </span>
                  <span>
                    <a href="/businessUnit/00200001" class="textTitle"> 知识产权</a>
                  </span>
                  <span> &nbsp;> 技术标准 </span>
                </div> -->
              </div>
              <div class="techologyRightWrap">
                <div class="technologyTableHd" v-if="listChild.length">
                  <div
                    class="fl technologyTableHdInner"
                    :class="{
                      active: activeHd == index,
                      technologyTableHdInner02: listChild.length == 2,
                      technologyTableHdInner03: listChild.length == 3,
                      technologyTableHdInner04: listChild.length == 4,
                      technologyTableHdInner05: listChild.length == 5
                    }"
                    v-for="(item, index) in listChild"
                    :key="index"
                    @click="handleTableHd(item, index)"
                  >
                    <div class="inner">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
                <list-page ref="listPage" v-if="viewPage.listPage" :paramsName="paramsName"></list-page>
                <list-img ref="listImg" v-if="viewPage.listImg" :paramsName="paramsName"></list-img>
                <list-pic ref="listPic" v-if="viewPage.listPic" :paramsName="paramsName"></list-pic>
                <list-table
                  ref="listTable"
                  v-if="viewPage.listTable"
                  :paramsName="paramsName"
                  :listChild="listChild"
                ></list-table>
                <list ref="list" v-if="viewPage.list" :paramsName="paramsName"></list>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <list-detail v-if="isDeail" ref="listDetail" :id="listId"></list-detail>
          <new-list v-if="isList" ref="newList" :id="listId"></new-list> -->
    </div>
  </div>
</template>

<script>
import { getChildChannel, getChannelInfo } from '@/api/common'
import listPage from './components/listPage.vue' //列表
import listImg from './components/listImg.vue' //列表-图片
import listPic from './components/listPic.vue' //列表-照片
import listTable from './components/listTable.vue' //列表-表格
import list from './components/list.vue' //列表
// import listDetail from '@/components/listDetail/index.vue'
// import newList from '@/components/newList/index.vue'
export default {
  components: {
    // listDetail,
    // newList
    listPage,
    listImg,
    listPic,
    listTable,
    list
  },
  data() {
    return {
      viewSideSubTitle: 0,
      fistShow: true,
      activeHd: '',
      listChild: '',
      paramsName: '',
      viewPage: {
        list: false,
        listImg: false,
        listPage: false,
        listPic: false,
        listTable: false
      },
      control: 0 ,
      isShow : false,
      isShow2: false,
      techologyRightHd: '概况',
      techologyRightInfo :'',
      techologyRightInfo2 :'',
      viewSideSub: '',
      viewSideSubInner: false,
      techologyHd: '科 技 创 新',
      listId: '',
      sideId: '',
      isDeail: false,
      isList: false,
      active: '',
      pathtitle: '',
      mathedTitle: '业务板块',
      indexImg: '',
      navSubList: [],
      // catalog: 1. 文章; 2. 列表; 3. 图片列表;
      techologySide: [],
      techologySide2: [
      //   {
      //     entTChannelUuid: '0001',
      //     name: '概况',
      //     url: '',
      //     catalog: 1,
      //     child: [],
      //     prop: 'general'
      //   },
      //   {
      //     entTChannelUuid: '0002',
      //     name: '核心技术',
      //     url: '',
      //     catalog: 1,
      //     child: [],
      //     prop: 'generalHxjs'
      //   },
      //   {
      //     entTChannelUuid: '0003',
      //     name: '科技人才',
      //     url: '',
      //     catalog: 1,
      //     prop: 'generalKjrc',
      //     child: [
      //       {
      //         entTChannelUuid: '0003001',
      //         name: '甘肃建筑科学技术委员会',
      //         url: '',
      //         catalog: 4,
      //         prop: 'kjrcKxjswyh',
      //         child: []
      //       },
      //       {
      //         entTChannelUuid: '0003002',
      //         name: '甘肃省首席专家',
      //         url: '',
      //         catalog: 3,
      //         prop: 'kjrcSxzj'
      //       },
      //       {
      //         entTChannelUuid: '0003003',
      //         name: '国务院特殊津贴专家',
      //         url: '',
      //         catalog: 3,
      //         prop: 'kjrcTsjtzj'
      //       },
      //       {
      //         entTChannelUuid: '0003004',
      //         name: '甘肃省领军人才、正高级工程师',
      //         url: '',
      //         catalog: 3,
      //         prop: 'kjrcLjrc'
      //       },
      //       {
      //         entTChannelUuid: '0003005',
      //         name: '甘肃建投科技领军人才',
      //         url: '',
      //         catalog: 3,
      //         prop: 'kjrcKjljrc'
      //       },
      //       {
      //         entTChannelUuid: '0003006',
      //         name: '甘肃建投金牌工匠',
      //         url: '',
      //         catalog: 3,
      //         prop: 'kjrcJpgj'
      //       }
      //     ]
      //   },
      //   {
      //     entTChannelUuid: '0004',
      //     name: '知识产权',
      //     url: '',
      //     catalog: 1,
      //     prop: 'generalZscq',
      //     child: [
      //       {
      //         entTChannelUuid: '0004001',
      //         name: '技术标准',
      //         url: '',
      //         catalog: 4,
      //         prop: 'zscqJsbz',
      //         child: [
      //           {
      //             entTChannelUuid: '0004001001',
      //             name: '国家标准',
      //             url: '',
      //             catalog: 4,
      //             prop: 'zscqJsbzGj'
      //           },
      //           {
      //             entTChannelUuid: '0004001002',
      //             name: '行业标准',
      //             url: '',
      //             catalog: 4,
      //             prop: 'zscqJsbzHy'
      //           },
      //           {
      //             entTChannelUuid: '0004001003',
      //             name: '地方标准',
      //             url: '',
      //             catalog: 4,
      //             prop: 'zscqJsbzDf'
      //           },
      //           {
      //             entTChannelUuid: '0004001004',
      //             name: '团体标准',
      //             url: '',
      //             catalog: 4,
      //             prop: 'zscqJsbzTt'
      //           },
      //           {
      //             entTChannelUuid: '0004001005',
      //             name: '甘肃建投序列标准',
      //             url: '',
      //             catalog: 4,
      //             prop: 'zscqJsbzGsjt'
      //           }
      //         ]
      //       },
      //       {
      //         entTChannelUuid: '0004002',
      //         name: '工法',
      //         url: '',
      //         catalog: 4,
      //         prop: 'zscqGf',
      //         child: [
      //           {
      //             entTChannelUuid: '0004002001',
      //             name: '国家级',
      //             url: '',
      //             catalog: 4,
      //             prop: 'zscqGfGjj'
      //           },
      //           {
      //             entTChannelUuid: '0004002001',
      //             name: '省级',
      //             url: '',
      //             catalog: 4,
      //             prop: 'zscqGfSj'
      //           },
      //           {
      //             entTChannelUuid: '0004002001',
      //             name: '甘肃建投企业级',
      //             url: '',
      //             catalog: 4,
      //             prop: 'zscqGfJtj'
      //           }
      //         ]
      //       },
      //       {
      //         entTChannelUuid: '0004003',
      //         name: '专利',
      //         url: '',
      //         catalog: 4,
      //         prop: 'zscqZl',
      //         child: [
      //           {
      //             entTChannelUuid: '0004003001',
      //             name: '发明',
      //             url: '',
      //             catalog: 3,
      //             prop: 'zscqZlFm'
      //           },
      //           {
      //             entTChannelUuid: '0004003002',
      //             name: '使用新型',
      //             url: '',
      //             catalog: 4,
      //             prop: 'zscqZlSyxx'
      //           },
      //           {
      //             entTChannelUuid: '0004003003',
      //             name: '外观设计',
      //             url: '',
      //             catalog: 4,
      //             prop: 'zscqZlWgsj'
      //           }
      //         ]
      //       },
      //       {
      //         entTChannelUuid: '0004004',
      //         name: '软件著作全',
      //         url: '',
      //         catalog: 3,
      //         prop: 'zscqRjzzq'
      //       }
      //     ]
      //   },
      //   {
      //     entTChannelUuid: '0005',
      //     name: '科技奖励',
      //     url: '',
      //     catalog: 1,
      //     prop: 'generalKjjl',
      //     child: [
      //       {
      //         entTChannelUuid: '0005001',
      //         name: '国家级',
      //         url: '',
      //         catalog: 3,
      //         prop: 'KjjlGjj'
      //       },
      //       {
      //         entTChannelUuid: '0005002',
      //         name: '省部级',
      //         url: '',
      //         catalog: 3,
      //         prop: 'KjjlSbj'
      //       },
      //       {
      //         entTChannelUuid: '0005003',
      //         name: '市厅级',
      //         url: '',
      //         catalog: 4,
      //         prop: 'KjjlStj'
      //       },
      //       {
      //         entTChannelUuid: '0005004',
      //         name: '甘肃建投企业级',
      //         url: '',
      //         catalog: 4,
      //         prop: 'KjjlJtj'
      //       }
      //     ]
      //   },
      //   {
      //     entTChannelUuid: '0006',
      //     name: '科技项目',
      //     url: '',
      //     catalog: 1,
      //     prop: 'generalKjxm',
      //     child: [
      //       {
      //         entTChannelUuid: '0006001',
      //         name: '国家级',
      //         url: '',
      //         catalog: 4,
      //         prop: 'kjxmGjj'
      //       },
      //       {
      //         entTChannelUuid: '0006002',
      //         name: '省级',
      //         url: '',
      //         catalog: 4,
      //         prop: 'kjxmSj'
      //       },
      //       {
      //         entTChannelUuid: '0006003',
      //         name: '市厅级',
      //         url: '',
      //         catalog: 4,
      //         prop: 'kjxmStj'
      //       },
      //       {
      //         entTChannelUuid: '0006004',
      //         name: '甘肃建投企业级',
      //         url: '',
      //         catalog: 4,
      //         prop: 'kjxmJtj'
      //       }
      //     ]
      //   },
      //   {
      //     entTChannelUuid: '0007',
      //     name: '科技创新平台',
      //     url: '',
      //     catalog: 1,
      //     prop: 'generalKjcxpt',
      //     child: [
      //       {
      //         entTChannelUuid: '0007001',
      //         name: '企业/行业技术中心',
      //         url: '',
      //         catalog: 4,
      //         prop: 'kjcxptJszx'
      //       },
      //       {
      //         entTChannelUuid: '0007002',
      //         name: '博士后科研工作站',
      //         url: '',
      //         catalog: 1,
      //         prop: 'kjcxptKygzz'
      //       },
      //       {
      //         entTChannelUuid: '0007003',
      //         name: '工程研究中心',
      //         url: '',
      //         catalog: 1,
      //         prop: 'kjcxptYjzx'
      //       },
      //       {
      //         entTChannelUuid: '0007004',
      //         name: '国家高薪技术企业',
      //         url: '',
      //         catalog: 4,
      //         prop: 'kjcxptGxjsqy'
      //       },
      //       {
      //         entTChannelUuid: '0007005',
      //         name: '装配式建筑产业基地',
      //         url: '',
      //         catalog: 4,
      //         prop: 'kjcxptCyjd'
      //       },
      //       {
      //         entTChannelUuid: '0007006',
      //         name: '国家荒漠化防治试验示范基地',
      //         url: '',
      //         catalog: 3,
      //         prop: 'kjcxptSfjd'
      //       },
      //       {
      //         entTChannelUuid: '0007007',
      //         name: '其他',
      //         url: '',
      //         catalog: 4,
      //         prop: 'kjcxptQt',
      //         child: [
      //           {
      //             entTChannelUuid: '0007007001',
      //             name: '工业设计中心',
      //             url: '',
      //             catalog: 4,
      //             prop: 'kjcxptQtGysj'
      //           },
      //           {
      //             entTChannelUuid: '0007007002',
      //             name: '技术转移示范机构',
      //             url: '',
      //             catalog: 4,
      //             prop: 'kjcxptQtJszy'
      //           }
      //         ]
      //       }
      //     ]
      //   },
      //   {
      //     entTChannelUuid: '0008',
      //     name: 'BIM技术应用',
      //     url: '',
      //     catalog: 1,
      //     prop: 'generalBimjsyy',
      //     child: [
      //       {
      //         entTChannelUuid: '0008001',
      //         name: '国家级',
      //         url: '',
      //         catalog: 3,
      //         prop: 'BimjsyyGjj'
      //       },
      //       {
      //         entTChannelUuid: '0008002',
      //         name: '省级',
      //         url: '',
      //         catalog: 4,
      //         prop: 'BimjsyySj'
      //       },
      //       {
      //         entTChannelUuid: '0008003',
      //         name: '甘肃建投企业级',
      //         url: '',
      //         catalog: 4,
      //         prop: 'BimjsyyJtj'
      //       }
      //     ]
      //   },
      //   {
      //     entTChannelUuid: '0009',
      //     name: '科技示范工程',
      //     url: '',
      //     catalog: 1,
      //     prop: 'generalKjsfgc',
      //     child: [
      //       {
      //         entTChannelUuid: '0009001',
      //         name: '国家级',
      //         url: '',
      //         catalog: 3,
      //         prop: 'kjsfgcGjj'
      //       },
      //       {
      //         entTChannelUuid: '0009002',
      //         name: '省级',
      //         url: '',
      //         catalog: 4,
      //         prop: 'kjsfgcSj'
      //       },
      //       {
      //         entTChannelUuid: '0009003',
      //         name: '甘肃建投企业级',
      //         url: '',
      //         catalog: 4,
      //         prop: 'kjsfgcJtj'
      //       }
      //     ]
      //   },
      //   {
      //     entTChannelUuid: '0010',
      //     name: '双碳政策研究',
      //     url: '',
      //     catalog: 1,
      //     prop: 'generalStzcyj',
      //     child: [
      //       {
      //         entTChannelUuid: '0010001',
      //         name: '政策文件',
      //         url: '',
      //         catalog: 2,
      //         prop: 'stzcyjZcwj'
      //       },
      //       {
      //         entTChannelUuid: '0010002',
      //         name: '规划方案',
      //         url: '',
      //         catalog: 2,
      //         prop: 'stzcyjGhfa'
      //       },
      //       {
      //         entTChannelUuid: '0010003',
      //         name: '技术报告',
      //         url: '',
      //         catalog: 2,
      //         prop: 'stzcyjJsbg'
      //       }
      //     ]
      //   },
      //   {
      //     entTChannelUuid: '0011',
      //     name: '科技快讯专栏',
      //     url: '',
      //     catalog: 1,
      //     prop: 'generalKjkxzl',
      //     child: [
      //       {
      //         entTChannelUuid: '0011001',
      //         name: '优秀案例',
      //         url: '',
      //         catalog: 2,
      //         prop: 'KjkxzlYxal'
      //       },
      //       {
      //         entTChannelUuid: '0011002',
      //         name: '获奖宣传',
      //         url: '',
      //         catalog: 2,
      //         prop: 'KjkxzlHjxc'
      //       },
      //       {
      //         entTChannelUuid: '0011003',
      //         name: '公示',
      //         url: '',
      //         catalog: 2,
      //         prop: 'KjkxzlGs'
      //       },
      //       {
      //         entTChannelUuid: '0011004',
      //         name: '公告',
      //         url: '',
      //         catalog: 2,
      //         prop: 'KjkxzlGg'
      //       }
      //     ]
      //   }
      ],
      chanellInfoUuid: '',
      isSlide: false
    }
  },
  computed: {},
  mounted() {
    var _this = this
    _this.active = _this.$route.params.id
    _this.sideId = _this.$route.params.id
    _this.getSubGrop()
    _this.getSubGropKjcx()
  },
  methods: {
    fistClick() {
      var _this = this
      _this.fistShow = false,
      console.log(12)
    },
    handleTableHd(item, index) {
      var _this = this
      _this.isShow2 = true
      _this.activeHd = index
      _this.viewPage = _this.$options.data().viewPage
      this.$nextTick(() => {
        if (item.catalog == 1) {
          _this.viewPage.listPage = true
        } else if (item.catalog == 2) {
          _this.viewPage.list = true
        } else if (item.catalog == 3) {
          _this.viewPage.listImg = true
        } else if (item.catalog == 4) {
          _this.viewPage.listTable = true
        }
        _this.paramsName = item.entTChannelUuid
        let params1 = {
          channelUuid: _this.paramsName
        }
        getChannelInfo(params1).then(res => {
          const { code, data } = res
          if (code === 1 && data) {
            _this.techologyRightInfo2 = data.name
          }
        })
      })
    },
    handleSideHd(item) {
      var _this = this
      _this.viewSideSub = item.entTChannelUuid
      _this.viewSideSubTitle = item.entTChannelUuid
      _this.viewSideSubInner = 0
      _this.techologyRightInfo = ''
      _this.isShow = false
      _this.viewPage = _this.$options.data().viewPage
      this.$nextTick(() => {
        _this.viewPage.listPage = true
        _this.paramsName = item.entTChannelUuid
        _this.listChild = []
        _this.techologyRightHd = item.name
      })
      if (_this.viewSideSub == _this.paramsName) {
        _this.isSlide = !_this.isSlide
        if (_this.isSlide) {
          _this.viewSideSub = item.entTChannelUuid
        } else {
          _this.viewSideSub = 0
        }
        
      } else {
        _this.isSlide = true
        _this.viewSideSub = item.entTChannelUuid
      }
      // if((_this.viewSideSub == _this.paramsName) && _this.isSlide){
      //   _this.viewSideSub = !_this.viewSideSub
      //   _this.isSlide = !_this.isSlide
      // } else {
      //   _this.viewSideSub = item.entTChannelUuid
      //   _this.isSlide = true
      // }

    },
    handleSideSubInner(item) {
      var _this = this
      _this.isShow = true
      _this.viewSideSubInner = item.entTChannelUuid
      _this.viewPage = _this.$options.data().viewPage
      _this.activeHd = 0
      let params1 = {
        channelUuid: _this.viewSideSubInner
      }
      getChannelInfo(params1).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.techologyRightInfo = data.name
          // console.log(123,data.name)
        }
      })
      if (item.child && item.child.length) {
        _this.isShow2 = true
        this.$nextTick(() => {
          if (item.child[0].catalog == 1) {
            _this.viewPage.listPage = true
          } else if (item.child[0].catalog == 2) {
            _this.viewPage.list = true
          } else if (item.child[0].catalog == 3) {
            _this.viewPage.listImg = true
          } else if (item.child[0].catalog == 4) {
            _this.viewPage.listTable = true
          }
          _this.paramsName = item.child[0].entTChannelUuid
          let params1 = {
            channelUuid: _this.paramsName
          }
          getChannelInfo(params1).then(res => {
            const { code, data } = res
            if (code === 1 && data) {
              _this.techologyRightInfo2 = data.name
            }
          })
          _this.listChild = item.child
        })
      } else {
        this.$nextTick(() => {
          _this.isShow2 = false
          _this.techologyRightInfo2 = ''
          if (item.catalog == 1) {
            _this.viewPage.listPage = true
          } else if (item.catalog == 2) {
            _this.viewPage.list = true
          } else if (item.catalog == 3) {
            _this.viewPage.listImg = true
          } else if (item.catalog == 4) {
            _this.viewPage.listTable = true
          }
          _this.paramsName = item.entTChannelUuid
          _this.listChild = []
        })
      }
    },
    // 获取二级栏目-科技创新
    getSubGropKjcx() {
      var _this = this
      let data = {
        applicationUuid: _this.webId,
        parentUuid: '81bf5506-c4a8-49b7-b091-6506465fe16e'
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.techologySide = data
          _this.viewSideSub = _this.techologySide[0].entTChannelUuid
          _this.viewSideSubTitle = _this.techologySide[0].entTChannelUuid
          _this.listId = _this.techologySide[0].entTChannelUuid
          _this.paramsName = _this.techologySide[0].entTChannelUuid
          _this.techologyRightHd = _this.techologySide[0].name
          _this.isDeail = true
          _this.viewPage.listPage = true
        }
      })
    },
    // 获取二级栏目
    getSubGrop() {
      var _this = this
      let data = {
        applicationUuid: _this.webId,
        parentUuid: '0020'
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.navSubList = data
          data.length &&
            data.forEach(line => {
              if (line.entTChannelUuid == _this.sideId) {
                _this.pathtitle = line.name
                _this.indexImg = line.fileName
                if (line.catalog == 1) {
                  this.isDeail = true
                } else {
                  this.isList = true
                }
              }
            })
        }
      })
    },
    handleTab(item) {
      this.isDeail = false
      this.isList = false
      if (item.entTChannelUuid == '00190007') {
        this.$router.push(`/pressCenter/radioReport/${item.child[0].entTChannelUuid}`)
      } else if (item.entTChannelUuid == '00200006') {
        this.$router.push(`/businessUnit/technology/${item.entTChannelUuid}`)
      } else {
        this.$router.push(`/businessUnit/${item.entTChannelUuid}`)
      }
      // if (item.entTChannelUuid == '00190007') {
      //   this.$router.push(`/pressCenter/radioReport/${item.child[0].entTChannelUuid}`)
      // } else {
      //   this.$router.push(`/businessUnit/${item.entTChannelUuid}`)
      // }
      this.pathtitle = item.name
      this.active = item.entTChannelUuid
      this.listId = item.entTChannelUuid
      this.indexImg = item.fileName
      this.$nextTick(() => {
        if (item.catalog == 1) {
          this.isDeail = true
        } else {
          this.isList = true
        }
      })
    },
    getChannelInfo() {
      var _this = this
      let params1 = {
        channelUuid: _this.chanellInfoUuid
      }
      getChannelInfo(params1).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          console.log(123)
        }
      })
    },
  },
  watch: {}
}
</script>

<style lang="scss">
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff2?t=1644917438066') format('woff2'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff?t=1644917438066') format('woff'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.ttf?t=1644917438066') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 22px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}
.businessUnit {
  width: 100%;
  height: auto;
  overflow: hidden;
  min-height: 902px;
}
.businessUnit .ziHead {
  width: 100%;
  height: 417px;
  overflow: hidden;
  background-size: 100% 100%;
}

.businessUnit .ziNav {
  height: 42px;
  border-bottom: 1px #cacaca solid;
  margin-top: 15px;
  width: 100% !important;
}
.businessUnit .navMain {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}
.businessUnit .navMainLeft {
  width: 900px;
  float: left;
}
.businessUnit .navMainLeft a {
  height: 42px;
  line-height: 42px;
  display: inline-block;
  margin: 0px 5px;
  padding: 0px 10px;
  float: left;
}
.businessUnit .navMainLeft a:hover {
  color: #0263b2;
  border-bottom: 2px #0263b2 solid;
}
.businessUnit .navMainLeft a.active {
  color: #0263b2;
  border-bottom: 2px #0263b2 solid;
}
.businessUnit .navMainRight {
  width: 300px;
  float: right;
  overflow: hidden;
  font-size: 13px;
  color: #a2a2a2;
  margin-top: 12px;
  line-height: 24px;
}
.businessUnit .navMainRight span {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
  vertical-align: top;
}

.businessUnit .navMainRight .textTitle {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
}
.businessUnit .ziBase {
  width: auto;
  height: auto;
  overflow: hidden;
}
.businessUnit .ziContact {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.businessUnit .rowContact {
  margin-top: 0px;
  padding-bottom: 20px;
}
.navMainInner {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  height: 42px;
  line-height: 43px;
  box-sizing: border-box;
  font-size: 17px;
}
.navMainInner.active {
  border-bottom: 2px solid #0263b2;
}
.techologyBox {
  width: 1200px;
  margin: 0 auto;
}
.techologyWrap {
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 1px 10px #b5b5b5;
  padding: 25px 30px 0;
}
.techologyLeft,
.techologyRight {
  height: auto;
  overflow: hidden;
  margin-bottom: -100000px;
  padding-bottom: 100000px;
}
.techologyLeft {
  float: left;
  width: 243px;
  background-color: #f4f4f4;
}
.techologyRight {
  float: right;
  width: 880px;
}
.techologyLeftHd {
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  background-color: #0263b2;
  border-radius: 4px;
}
.techologyLeftLine {
  height: 6px;
  background-color: #fff;
}
.techologyLeftWrap {
  padding-bottom: 20px;
}
.techologySideHd {
  height: 46px;
  line-height: 46px;
  padding-left: 20px;
  padding-right: 20px;
  // background-color: #f4f4f4;
  font-size: 16px;
  // font-weight: bold;
  cursor: pointer;
  border-bottom: 1px solid #d5d5d5;
  position: relative;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  .ico {
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    right: 0px;
    top: 2px;
    font-size: 18px;
    font-weight: bold;
    color: #868a8f;
    -moz-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }
  &:hover {
    color: #0263b2;
  }
  &.active {
    color: #0263b2;
    background-color: #bcd2e9;
    // .ico {
    //   color: #0263b2;
    //   transform: rotate(90deg);
    // }
  }
  &.on .ico {
    color: #0263b2;
    transform: rotate(90deg);
  }
}
.techologySideSub {
  padding-left: 20px;
}
.techologySideSubInner {
  line-height: 20px;
  font-size: 14px;
  cursor: pointer;
  margin: 9px 0;
  position: relative;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  position: relative;
  padding-left: 15px;
  padding-right: 10px;
  .dian {
    position: absolute;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #343e45;
    left: 5px;
    top: 9px;
  }
  &:hover {
    color: #0162b3;
  }
  &.active {
    color: #0238cf;
  }
}
.techologyRightHd {
  padding: 15px 0 15px 15px;
  border-bottom: 1px solid #d5d5d5;
  &::after {
    display: block;
    width: 0;
    height: 0;
    content: '';
    clear: both;
    overflow: hidden;
  }
}
.techologyRightHdTit {
  float: left;
  height: 24px;
  line-height: 24px;
  padding-left: 21px;
  color: #0263b2;
  border-left: 5px solid #0263b2;
  font-size: 16px;
  font-weight: bold;
}
.techologyRightHdTitInfo{
   height: 24px;
  line-height: 24px;
  color: #0263b2;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}
.techologyRightHdRight {
  float: right;
  height: 24px;
  line-height: 24px;
  span,
  a {
    font-size: 14px;
    vertical-align: middle;
    color: #868a8f;
  }
  i {
    vertical-align: middle;
  }
}
.techologyRightWrap {
  padding: 15px 15px 30px;
}
.technologyTableHd {
  margin-bottom: 10px;
  &::after {
    display: block;
    width: 0;
    height: 0;
    content: '';
    clear: both;
    overflow: hidden;
  }
}
.technologyTableHdInner {
  float: left;
  width: 20%;
  height: 38px;
  line-height: 38px;
  text-align: center;
  cursor: pointer;
  .inner {
    height: 100%;
    color: #fff;
    font-weight: bold;
    background-color: #A6A9AC;
    border-left: 1px solid #fff;
    -moz-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    &:hover {
      background-color: #0263b2;
    }
  }
  &:first-child .inner {
    border-left: none;
  }
  &.active .inner {
    background-color: #0263b2;
  }
}
.technologyTableHdInner02 {
  width: 50%;
}
.technologyTableHdInner03 {
  width: 33.33%;
}
.technologyTableHdInner04 {
  width: 25%;
}
.technologyTableHdInner05 {
  width: 20%;
}
.technologyTableWrap {
  .el-table th.el-table__cell {
    background-color: #e0e0e8;
    padding: 6px 0;
    border-left: 1px solid #fff;
    color: #0263b2;
  }
}
@media (max-width: 1300px) {
  .businessUnit .navMain,
  .companyProfile,
  .techologyBox {
    width: 1000px;
  }
  .businessUnit .navMainLeft,
  .businessUnit .navMainRight {
    width: auto;
  }
  .techologyRight {
    width: 686px;
  }
}
@media (max-width: 992px) {
  .businessUnit .navMain,
  .companyProfile,
  .techologyBox {
    width: 96%;
  }
  .companyProfile {
    padding: 20px 20px;
  }
  .companyProfile .contactBody video {
    max-width: 100% !important;
  }
  .companyProfile img {
    max-width: 100% !important;
  }
  .businessUnit .ziHead {
    height: 362px;
  }
  .techologyWrap {
    padding: 20px 10px 0;
  }
  .techologyLeft {
    width: 23%;
  }
  .techologyRight {
    width: 76%;
  }
}
@media (max-width: 768px) {
  .businessUnit .navMainLeft {
    width: 100%;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .businessUnit .navMainRight {
    display: none;
  }
  .businessUnit .ziHead {
    height: 200px;
  }
  .businessUnit .ziContact {
    padding: 0;
    margin: 0;
  }
  .companyProfile {
    padding: 20px 10px;
  }
  .contactBody h1 {
    font-size: 22px !important;
  }
  .techologyLeft,
  .techologyRight {
    width: 100%;
  }
  .techologyRight {
    background-color: #fff;
    padding-top: 10px;
  }
  .techologyLeftHd {
    display: none;
  }
  .techologyWrap {
    padding: 0px 0px 0;
  }
  .techologyLeftLine {
    display: none;
  }
  .techologySideHd {
    height: 40px;
    line-height: 40px;
  }
  .techologySideSubInner {
    margin: 6px 0;
  }
  .techologyLeftWrap {
    padding: 0;
  }
  .techologyRightHd {
    padding: 10px 0 10px 10px;
  }
  .techologyRightHdTit {
    padding-left: 12px;
  }
  .techologyRightWrap {
    padding: 10px 10px 20px;
  }
  .techologyBox {
    margin-top: 10px;
  }
  .technologyPage {
    overflow-x: auto;
  }
  .technologyTableHdInner .inner {
    font-size: 12px;
  }
}
</style>
