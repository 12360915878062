<template>
  <div class="technologyImg">
    <el-row :gutter="20" class="technologyList technologyListPc" v-for="(item, index) in newsList" :key="index + '001'">
      <el-col :span="7">
        <div class="listImg">
          <!-- <img :src="item.thumb1" alt=""> -->
          <el-image style="width: 100%; height: 100%" :src="item.thumb1" fit="contain"></el-image>
        </div>
      </el-col>
      <el-col :span="17">
        <div class="listCont">
          <div class="tit">{{ item.title }}</div>
          <div class="des">{{ item.artDesc }}</div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="5" class="technologyList technologyListMobile" v-for="(item, index) in newsList" :key="index">
      <el-col :span="7">
        <div class="listImg">
          <!-- <img :src="item.thumb1" alt=""> -->
          <el-image style="width: 100%; height: 100%" :src="item.thumb1" fit="contain"></el-image>
        </div>
      </el-col>
      <el-col :span="17">
        <div class="listCont">
          <div class="tit">{{ item.title }}</div>
          <div class="des">{{ item.artDesc }}</div>
        </div>
      </el-col>
    </el-row>
    <!-- <div class="pagin" style="text-align: center;">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        :pager-count="11"
        layout="sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div> -->
  </div>
</template>
<script>
import { articles } from '@/api/common'
export default {
  props: {
    paramsName: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      // newsList: [],
      pageSize: 2000,
      pageNum: 1,
      total: 0,
      content: '',
      newsList: []
    }
  },
  mounted() {
    var _this = this
    this.$nextTick(() => {
      _this.articles()
    })
  },
  filters: {
    filtersText(val) { // 要判断一下,如果是空就返回空字符串,不然会报错
      if (val != null && val != '') {
        var reg = /[\u4e00-\u9fa5]/g
        var names = val.match(reg)
        val = names.join('')
        return val.substr(0, 50);//获取富文本文字内容的前50个字符
      } else return ''
    }
  },
  methods: {
    handleCurrentChange(val) {
      var _this = this
      _this.pageNum = val
      _this.articles()
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      var _this = this
      _this.pageSize = val
      _this.articles()
    },
    articles() {
      var _this = this

      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: _this.paramsName,
        pageNum: _this.pageNum,
        pageSize: _this.pageSize
      }
      articles(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsList = data.rows
          _this.total = data.totalRows
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.technologyList {
  margin: 20px 0;
}
.listImg {
  height: 162px;
  overflow: hidden;
  text-align: center;
  // img {
  //   width: 100%;
  //   height: 100%;
  // }
}
.listCont {
  .tit {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  }
  .des {
    margin-top: 8px;
    font-size: 14px;
    line-height: 24px;
  }
}
.technologyListMobile {
  display: none;
}
@media (max-width: 768px) {
  // .listImg {
  //   height: 110px;
  // }
  .technologyListPc {
    display: none;
  }
  .technologyListMobile {
    display: block;
  }
}
</style>
