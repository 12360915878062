/*******************科技创新表格列表******************/
export const listData = {
  // 甘肃建筑科学技术委员会
  kjrcKxjswyh: {
    content: `
    <table style="border-collapse: collapse; width: 100%; height: 14255.5px;" border="1" width="916" cellspacing="0" cellpadding="0"><colgroup><col style="width: 4.6858%;" width="99" /> <col style="width: 7.84599%;" width="119" /> <col style="width: 10.7928%;" width="355" /> <col style="width: 28.2192%;" width="426" /> <col style="width: 13.9484%;" width="175" /> <col style="width: 24.0828%;" width="505" /> <col style="width: 10.4613%;" width="155" /> </colgroup>
    <tbody>
    <tr style="height: 44.75px;">
    <td class="et6" style="height: 44.75px; width: 917.35pt;" colspan="7" width="1834" height="45">一、集团公司内部人员（273人）</td>
    </tr>
    <tr style="height: 44.75px; background-color: #ecf0f1;">
    <td class="et8" style="height: 44.75px; width: 49.55pt;" width="99" height="45">序号</td>
    <td class="et8" style="width: 59.55pt; height: 44.75px;" width="119">姓名</td>
    <td class="et8" style="width: 177.55pt; height: 44.75px;" width="355">工作单位</td>
    <td class="et8" style="width: 213.05pt; height: 44.75px;" width="426">职务</td>
    <td class="et8" style="width: 87.55pt; height: 44.75px;" width="175">职&nbsp; 称</td>
    <td class="et8" style="width: 252.55pt; height: 44.75px;" width="505">从事专业</td>
    <td class="et9" style="width: 77.55pt; height: 44.75px;" width="155">备注</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">1</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">苏跃华</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">集团公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">主任&nbsp; 委员</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">2</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">束水龙</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">集团公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">副主任委员</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">3</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">胡继河</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">集团公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">测量工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">副主任委员</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">4</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">冯力强</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">集团公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">防灾减灾工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">5</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">李锁林</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">集团公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">6</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">陈志亮</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">集团公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et12" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">7</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">胡&nbsp; 岩</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">集团公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">工商管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">8</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">李生银</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">集团公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">应急安全（公司管理）部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">9</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">吴&nbsp; 军</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">集团公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">应急安全（公司管理）部副部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et10" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">10</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">张春生</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">集团公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">应急安全（公司管理）部一级主管</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">11</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">张孝斌</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">集团公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">专职外部董事</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工程管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">12</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">侯德席</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">集团公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">专职外部董事</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">电气技术</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">13</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">潘存瑞</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">集团公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">技术中心总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">14</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">刘吉林</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">集团公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">技术中心副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑材料</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">15</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">万年青</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">集团公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">技术中心技术管理部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">16</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">毛志童</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">集团公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">区域经济事业部总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">结构工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">17</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">牛昌林</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">科技公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">18</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">吴小燕</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">科技公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">/</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">焊接</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">19</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">周&nbsp; 岩</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">科技公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">/</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">20</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">魏宏亮</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">科技公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">科技研发部部长</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">建筑与土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">21</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">牛彦俊</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">科技公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">副总工程师/BIM技术中心主任</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">建筑与土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">22</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">闫汝刚</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">一建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">23</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">马小成</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">一建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">建筑经济</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">24</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">贺军平</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">25</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">谢国权</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">机电一体化</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">26</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">崔&nbsp; 清</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">/</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">27</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">张宝恒</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">董事会秘书</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">热能工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">28</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">蒋&nbsp; 伟</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">安质部副部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">29</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王宏军</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">科技部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">30</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">何宗锋</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">一建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">科技部副部长</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">31</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王高峰</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">32</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">安新虎</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">企业管理部副部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">33</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">雒宏宏</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">机电一体化</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">34</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">李向阳</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">一建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司总工</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">35</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">李记恒</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">一建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司总工</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">工业与民用建筑</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">36</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">牟仲平</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">一建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司总工</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">工业与民用建筑</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">37</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">程宏斌</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">一建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司总工</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">电气工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">38</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">张立天</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">二建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">39</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">牟继升</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">二建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">/</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建、国民经济学</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">40</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">张国平</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">二建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">41</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">赵&nbsp; 萍</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">二建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">技术质量部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">42</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">陈立延</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建、国民经济学</td>
    <td class="et10" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">43</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">肖&nbsp; 军</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">给排水</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">44</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">牛世平</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">45</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">张渭军</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">建筑与土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">46</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">张&nbsp; 华</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">房屋建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">47</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">刘许春</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">董事会秘书</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">48</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">兰永竞</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">科技部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">49</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">刘海江</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">安全生产部副部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑经济管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">50</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">李&nbsp; 超</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">科技研发中心副主任</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">51</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">张志明</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">机械设备公司经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">农业机械化</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">52</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">王进寿</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">53</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">王&nbsp; 军</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司副经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">54</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">赵&nbsp; 银</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司副经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">55</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">王继明</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司副经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程技术</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">56</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">牟建昌</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司副经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">57</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">李志斌</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">58</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">宋永刚</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司技术质量科科长</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">59</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">范&nbsp; 琳</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司技术质量科科长</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">工程管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">60</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">张秉钧</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">三建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司副经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">房屋建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">61</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">李明新</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">四建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et10" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">62</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">吴富明</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">四建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">63</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">哈晓春</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">四建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">64</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">满吉昌</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">四建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">/</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">65</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">钟&nbsp; 玲</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">四建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">技术中心主任</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">66</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">赵&nbsp;恬&nbsp;&nbsp;&nbsp;</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">四建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">技术中心副主任</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">工程管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">67</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">高建斌</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">四建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">技术中心管理人员</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">焊接工艺与设备</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">68</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">韦动彰</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">四建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">天成检测公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">69</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">苗承智</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">四建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">金结公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑经济管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">70</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">蔡润梅</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">四建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">建设公司总工</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">71</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">张海峰</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">四建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">建安公司总工</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">给水排水工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">72</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">王世琼</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">四建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">建安公司技术质量部长</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">建筑环境与设备工程（暖通）</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">73</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">代忠华</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">四建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">建安公司管理人员</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">给排水工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">74</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">韩贵毅</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">四建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">嘉峪关公司总工</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">工业与民用建筑</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">75</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">曹双麟</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">四建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">装饰公司经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">艺术设计（装饰）</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">76</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">王&nbsp; 浩</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">四建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">二公司总工</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">77</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">景生辉</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">四建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">安全总监</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">78</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">周文成</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">四建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">五公司总工</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">79</td>
    <td class="et15" style="width: 59.55pt; height: 44.75px;" width="119">张克诚</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">五建公司</td>
    <td class="et15" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et15" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et15" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et12" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">80</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">马永炯</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">五建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">81</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">马&nbsp; 强</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">五建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">82</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">汪金满</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">五建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">科技质量部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">83</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">陈启忠</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">五建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">企业管理部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">84</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">肖照健</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">五建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">85</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王志新</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">五建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">86</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">石旭东</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">五建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">87</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">杨&nbsp; 忠</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">五建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司副经理、总工</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">88</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">殷同春</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">六建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et12" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">89</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">赵小明</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">六建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">90</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">黄&nbsp; 瑞</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">六建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">科技研发中心主任</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">91</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">冯宜玺</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">六建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">技术部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">92</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">张文莉</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">六建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">技术部副部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">农业建筑与环境工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">93</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">丁国友</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">六建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">项目管理部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工程管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">94</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">焦晓光</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">六建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">技术中心副主任</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et12" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">95</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">詹&nbsp; 磊</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">六建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">供热通风与空调</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">96</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">孙学武</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">六建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">97</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">田澍龙</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">六建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">98</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">曹笃彬</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">六建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et12" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">99</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">汪金喜</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">六建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司副经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工程管理</td>
    <td class="et12" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">100</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">刘志刚</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">六建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et12" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">101</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">刘玉美</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">六建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司技术科科长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工程管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">102</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">巩拴翠</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">六建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司技术科科长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">103</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">冯勇慧</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">104</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">辛钰林</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">105</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">孟&nbsp; 虹</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">科技部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">106</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">李&nbsp; 黎</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司副经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">107</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">骆&nbsp; 军</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">技术中心副主任</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">108</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">刘昊东</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">质量管理部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">109</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">朱石飞</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">安全生产部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">110</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">张&nbsp; 运</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">技术中心主任</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">111</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">俞春华</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">质量管理部副部长</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">112</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">董&nbsp; 强</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">设计公司经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">113</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">胡&nbsp; 斌</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">四分公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">114</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">杨凌志</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">暖通</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">115</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">李国辉</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">公路公司副经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">116</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">孙贤军</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">五分公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">117</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王&nbsp; 铨</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">暖通</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">118</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">黄&nbsp; 勇</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">构件公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">119</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">李&nbsp; 国</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">公路公司经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">工商管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">120</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">魏永全</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">荣厦公司总工程师</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">施工管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">121</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">杨海丽</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">七建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">基础桥梁市政分公司总工程师</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">工商管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">122</td>
    <td class="et17" style="width: 59.55pt; height: 44.75px;" width="119">郭想军</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">八建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">工商管理</td>
    <td class="et10" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">123</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">杨兴鸿</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">八建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et10" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">124</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">杨喜军</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">八建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">125</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">徐茂宏</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">八建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑施工与管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">126</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">潘&nbsp; 涛</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">八建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">科技部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">127</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">李建纲</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">八建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">技术中心主任</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">128</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">胡武杰</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">八建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总承包公司经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">暖通</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">129</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">姜广军</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">八建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">平凉公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">电气</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">130</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">何向明</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">八建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">兰州公司副经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">房屋建筑</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">131</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">吴&nbsp; 军</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">八建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">项目经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">132</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">杨鸿眉</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">九建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">133</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">谭&nbsp; 飞</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">九建公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">科技部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">134</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">王&nbsp; 涛</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">九建公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司总工</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">135</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">张永军</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一安公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">起重运输机械</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">136</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">陈&nbsp; 诤</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一安公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑与土木工程</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">137</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">孙彬强</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一安公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">138</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">程建通</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一安公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">139</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">马耀辉</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一安公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工业设备安装</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">140</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王贵生</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一安公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">科技部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">焊接、钢结构</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">141</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王小平</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一安公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">科技部副部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">给水排水工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">142</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">舒罗生</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一安公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">焊接</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">143</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">于水梅</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">一安公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">144</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">朱桂琴</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一安公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司副经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">暖通</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">145</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">伏国运</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一安公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工业电气</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">146</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">赵有锋</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一安公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">电气技术</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">147</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">谢怀军</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">一安公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">环境工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">148</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">雍晓强</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">安装公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工程机械</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">149</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">石福国</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">安装公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">焊接工艺与设备</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">150</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">宋喜兵</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">安装公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">机电一体化</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">151</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">来云浩</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">安装公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">安全生产部部长</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">热加工工艺及设备</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">152</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王强兵</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">安装公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">科技部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">电气技术</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">153</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">孙张绪</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">安装公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司副经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">焊接工艺与设备</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">154</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">白&nbsp; 龙</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">安装公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司副经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">电气自动化</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">155</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">刘宏彬</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">安装公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司副经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">化工设备与机械</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">156</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">杨红祥</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">安装公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">科技研发中心副主任</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">给水排水工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">157</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">蔡忠伟</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">158</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">葛&nbsp; 泽</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">159</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">王和泰</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">建筑经济与管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">160</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">胡晓勇</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">经济管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">161</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">马云龙</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">162</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">靳艳萍</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">163</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">冯&nbsp; 鸣</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">技术部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">164</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">陈&nbsp; 晓</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">全正检测公司董事长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">165</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">苏振宇</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">二公司经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">工程管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">166</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">李利军</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">二公司项目经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">167</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">张文德</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">二公司项目技术负责人</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">168</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">李&nbsp; 伟</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">新区公司经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">建筑经济与管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">169</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">杨鸿滨</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">庆阳公司总工</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">勘察工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">170</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">王雁欣</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">市政公司经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">工民建、战略哲学</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">171</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">巴文娟</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">八公司技术干事</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">工程管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">172</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">李建斌</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">机械公司经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">机械设计与制造</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">173</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">刘旭川</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">八公司生产经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">174</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">李福昌</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">新区公司项目经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">175</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">关维亮</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">土木公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">新区公司项目经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">176</td>
    <td class="et15" style="width: 59.55pt; height: 44.75px;" width="119">杨汝贤</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">建设公司</td>
    <td class="et15" style="width: 213.05pt; height: 44.75px;" width="426">/</td>
    <td class="et15" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et15" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et12" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">177</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">蒲小平</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">建设公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工业与民用建筑</td>
    <td class="et12" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">178</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">南作宾</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">建设公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">179</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">张来安</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">建设公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">180</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">于文生</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">建设公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">安全生产部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工程管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">181</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">张荣海</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">建设公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">技术与信息化部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">182</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">靳高明</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">建设公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">科技研发中心主任/BIM中心主任</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">183</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">车丁旺</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">建设公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">六公司总工</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">工业与民用建筑</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">184</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王志宏</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">置业公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑与土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">185</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王学明</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">置业公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工业与民用建筑</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">186</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">余维礼</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">置业公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">187</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">郭&nbsp; 震</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">科工公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">188</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">马张永</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">科工公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总经理兼总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">结构工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">189</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">温鸿武</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">科工公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">190</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">张志喜</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">科工公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">建筑环境与设备</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">191</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">董文东</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">科工公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">192</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">严永红</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">新区公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">193</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">刘文太</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">隧道公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">采矿工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">194</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王海明</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">隧道公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">&nbsp;高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">195</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">陈&nbsp; 磊</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">隧道公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">科技部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">196</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">丁&nbsp; 天</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">中甘国际</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">197</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">朱国岭</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">中甘国际</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">国内公司总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">经济管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">198</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">刘&nbsp; 明</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">中甘国际</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">加纳公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">热能工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">199</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">刘&nbsp; 炜</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">中甘国际</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">国内公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">200</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">刘昌凡</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">中甘国际</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">分公司副经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">201</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">彭俏静</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">中甘国际</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">国内公司技术部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">202</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">范本宝</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">中甘国际</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">安全生产部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">203</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">张永志</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">科研院公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">204</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">史智伟</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">科研院公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">205</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">金学菊</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">科研院公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">206</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王公胜</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">科研院公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">207</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王春景</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">科研院公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">国民经济学</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">208</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">徐毅明</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">科研院公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">综合事业部部长</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">209</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">刘&nbsp; 赟</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">科研院公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">绿建中心主任</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">建筑与土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">210</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">舒&nbsp; 蓉</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">科研院公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">岩土技术中心主任</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">结构工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">211</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">李俊杰</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">科研院公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">科技研发中心副主任/博士</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">结构工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">212</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">夏广录</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">科研院公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">建筑结构专业事业部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">213</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">肖娅婷</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">科研院公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">科技管理部副部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">214</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">代晓艳</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">科研院公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">企业管理部部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">215</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">冯炳超</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">科研院公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">勘察所总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">216</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">李&nbsp; 岩</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">科研院公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">综合事业部副部长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">计算机</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">217</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">李琳琳</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">科研院公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">河西检测中心主任</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">化学工程与工艺</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">218</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">罗崇德</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">/</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">219</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">马岷成</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">220</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">袁&nbsp; 宁</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">水利水电工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">221</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">安利宁</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">222</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王跃军</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">/</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">223</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">魏志刚</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">建工设计院总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑学</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">224</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">魏常荣</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">绿色建造公司总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑学</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">225</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">房&nbsp; 彬</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">建工设计院总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">226</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">杨彦霞</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">天水设计院总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">227</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">杨&nbsp; 立</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">生态分公司总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">228</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">刘&nbsp; 磊</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">绿色建造公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">229</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">陈&nbsp; 刚</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">生态分公司总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">230</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">张满红</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">副院长</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">231</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">陈赞国</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">所总建</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">建筑学</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">232</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">王聪贻</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">电气设计工程师</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">电气工程及其自动化</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">233</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">王正龙</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">生态工程设计公司电气专业副总工程师</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">电气自动化</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 61.6875px;">
    <td class="et10" style="height: 61.6875px; width: 49.55pt;" width="99" height="45">234</td>
    <td class="et13" style="width: 59.55pt; height: 61.6875px;" width="119">张重良</td>
    <td class="et13" style="width: 177.55pt; height: 61.6875px;" width="355">设计咨询公司</td>
    <td class="et13" style="width: 213.05pt; height: 61.6875px;" width="426">生态工程设计公司给排水专业副总工程师</td>
    <td class="et13" style="width: 87.55pt; height: 61.6875px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 61.6875px;" width="505">&nbsp;给水排水工程</td>
    <td class="et11" style="width: 77.55pt; height: 61.6875px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">235</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">韦&nbsp; 斌</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">生态工程设计公司二所总工程师</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">农业建筑与环境工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">236</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">张志刚</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">生态工程设计公司副经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">给水排水工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">237</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">阮德强</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">生态工程设计公司鉴定所所长</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">238</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">仇毅锋</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">生态工程设计公司鉴定所项目负责人</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">239</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">牛天锋</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">专业总工</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">给水排水</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">240</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">王&nbsp; 鹍</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">设计所长</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">电气工程及其自动化</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">241</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">丁&nbsp; 凯</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">建工设计公司技术部干事</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">给水排水工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">242</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">李&nbsp; 琼</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">设计所总工</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">243</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">梁建荣</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">设计咨询公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">分公司设计所所长</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">供暖通风与空气调节</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">244</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">牛向辉</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">装备公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">机械制造</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">245</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">朱龙祥</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">装备公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">党委副书记、总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">建筑机械</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">246</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">叶凤霞</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">装备公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">风景园林</td>
    <td class="et12" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">247</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">杨&nbsp; 龙</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">装备公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">/</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑机械</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">248</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王晓红</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">装备公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">/</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">电机电器及其控制</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">249</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">白树生</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">装备公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">航天新能源公司工程总监</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">材料成型与控制工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">250</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">李彩娴</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">装备公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">高级工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">腐蚀防护</td>
    <td class="et10" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">251</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">杨&nbsp; 安</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">重工科技</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">材料成型及控制（焊接方向）</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">252</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">赵&nbsp; 峰</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">重工科技</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工程机械</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">253</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">张永红</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">新能源公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">石油天然气储运</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">254</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">张忠强</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">新能源公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">工程管理部部长</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">255</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">殷敏纶</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">集成装配式公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">256</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">刘建军</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">集成装配式公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师/ 高级工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">&nbsp;高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">257</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">吴继德</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">集成装配式公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">258</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">闵&nbsp; 英</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">集成装配式公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">投资管理部部长</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">259</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">李柏生</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">天水绿色装配式公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">水文地质、工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">260</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">党利荣</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">天水绿色装配式公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程、钢结构</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">261</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">张丰川</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">生态公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">董事长</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">262</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">戴少军</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">生态公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">263</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">张敏君</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">生态公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">水暖与通风</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">264</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">许&nbsp; 鸿</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">生态公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">科技研发部部长</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">起重运输</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">265</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">石福顺</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">生态公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">副经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">机械制造工艺与设备</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">266</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">汪学彬</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">绿色建材公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">副董事长</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">267</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">秦超宁</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">绿色建材公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">268</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">杜康武</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">绿色建材公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">269</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王彦鹏</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">绿色建材公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">物理化学</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">270</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">罗&nbsp; 军</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">绿色建材公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工民建</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">271</td>
    <td class="et13" style="width: 59.55pt; height: 44.75px;" width="119">张慧斌</td>
    <td class="et13" style="width: 177.55pt; height: 44.75px;" width="355">绿色建材公司</td>
    <td class="et13" style="width: 213.05pt; height: 44.75px;" width="426">矿业公司安全总监</td>
    <td class="et13" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et13" style="width: 252.55pt; height: 44.75px;" width="505">机械设计制造及其自动化</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">272</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">温纯厚</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">陕新公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">273</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王&nbsp; 辉</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">河西公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总经理</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工程管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et18" style="height: 44.75px; width: 917.35pt;" colspan="7" width="1834" height="45">二、特邀外部专家（41人）</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">1</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">杜&nbsp; 翔</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">甘肃省建设工程造价站</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">站长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">结构工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">2</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">姬广庆</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">甘肃省墙改办</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">协会秘书长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑材料</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">3</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">高永强</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">青海省建设厅</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">/</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">岩土工程</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">4</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">冯大斌</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">中国建筑科学研究院</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副所长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">结构工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">5</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">张得俭</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">甘肃省机械科学研究院</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">/</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">农机科研</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">6</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">贺成柱</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">甘肃省机械科学研究院</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">/</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">机电一体化</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">7</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">冯启彪</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">甘肃省建材科研设计院</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">研究室主任</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑材料</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">8</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">田斌守</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">甘肃省建材科研设计院</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">绿色建筑</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">9</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">张举涛</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">甘肃省建筑设计研究院</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑结构</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">10</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">冯晓军</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">甘肃省建筑设计研究院</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">/</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">岩土工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">11</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">黄&nbsp; 锐</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">甘肃省建筑设计研究院</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">结构工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">12</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">程兴田</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">甘肃省农业机械管理站</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">研究员</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">研究员</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">农业机械</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">13</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">金光辉</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州市城乡规划设计院</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副院长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑学</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">14</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">李英民</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">重庆大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">教授</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">结构工程</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 61.6875px;">
    <td class="et10" style="height: 61.6875px; width: 49.55pt;" width="99" height="45">15</td>
    <td class="et10" style="width: 59.55pt; height: 61.6875px;" width="119">张敬书</td>
    <td class="et10" style="width: 177.55pt; height: 61.6875px;" width="355">兰州大学</td>
    <td class="et10" style="width: 213.05pt; height: 61.6875px;" width="426">教授</td>
    <td class="et10" style="width: 87.55pt; height: 61.6875px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 61.6875px;" width="505">土木工程设计,混凝土与钢筋混凝土结构,特种结构</td>
    <td class="et11" style="width: 77.55pt; height: 61.6875px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">16</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">黄&nbsp; 宁</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">教授</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程，工程管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">17</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王秀丽</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州理工大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">教授</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">结构工程</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">18</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">项长生</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州理工大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">教授</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">岩土工程</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">19</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">董建华</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州理工大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">教授</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">岩土工程</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">20</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">毕晓莉</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州理工大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">教授</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">城市规划</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">21</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">李鸿飞</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州理工大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副教授</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">副教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">城市规划</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">22</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">党星海</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州理工大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">建筑勘察设计院院长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">测绘工程，土木工程</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">23</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王文达</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州理工大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">教授</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">结构工程</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">24</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">叶帅华</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州理工大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">教授</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">地基基础工程，岩土工程，装配式结构</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">25</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">赵建昌</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州交通大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">教授</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">结构工程</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">26</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">刘振奎</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州交通大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副校长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程，工程管理</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">27</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">杨有海</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州交通大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">教授</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">特种建筑材料,路基工程,道路工程其他学科</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">28</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">张家玮</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州交通大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">创新创业学院院长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程设计,混凝土与钢筋混凝土结构</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">29</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王&nbsp; 旭</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州交通大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">副院长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">岩土工程</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">30</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">蔺鹏臻</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州交通大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">教授</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">桥梁工程</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">31</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">陈光武</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州交通大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">教授</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">机电专业</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">32</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王根会</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州交通大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">教授</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">33</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">王洪镇</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">西北民族大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">教授</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木工程材料</td>
    <td class="et14" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">34</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">曹万智</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">西北民族大学</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">院长</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑节能</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 61.6875px;">
    <td class="et10" style="height: 61.6875px; width: 49.55pt;" width="99" height="45">35</td>
    <td class="et10" style="width: 59.55pt; height: 61.6875px;" width="119">胡建琴</td>
    <td class="et10" style="width: 177.55pt; height: 61.6875px;" width="355">兰州石化职业技术学院</td>
    <td class="et10" style="width: 213.05pt; height: 61.6875px;" width="426">教授</td>
    <td class="et10" style="width: 87.55pt; height: 61.6875px;" width="175">教授</td>
    <td class="et10" style="width: 252.55pt; height: 61.6875px;" width="505">土木建筑工程施工,土木建筑结构,职业技术教育学</td>
    <td class="et11" style="width: 77.55pt; height: 61.6875px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">36</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">常自昌</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">甘肃长城建设集团</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">37</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">邵文忠</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">兰州二建集团有限公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">工程管理</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">38</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">韩小平</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">甘肃建设设计咨询集团</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">专业总工</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">建筑工程</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 62px;">
    <td class="et10" style="height: 62px; width: 49.55pt;" width="99" height="45">39</td>
    <td class="et10" style="width: 59.55pt; height: 62px;" width="119">陈天镭</td>
    <td class="et10" style="width: 177.55pt; height: 62px;" width="355">兰州有色冶金设计研究院有限公司</td>
    <td class="et10" style="width: 213.05pt; height: 62px;" width="426">/</td>
    <td class="et10" style="width: 87.55pt; height: 62px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 62px;" width="505">建筑与土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 62px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 44.75px;">
    <td class="et10" style="height: 44.75px; width: 49.55pt;" width="99" height="45">40</td>
    <td class="et10" style="width: 59.55pt; height: 44.75px;" width="119">陈瑛卿</td>
    <td class="et10" style="width: 177.55pt; height: 44.75px;" width="355">金川集团股份有限公司</td>
    <td class="et10" style="width: 213.05pt; height: 44.75px;" width="426">/</td>
    <td class="et10" style="width: 87.55pt; height: 44.75px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 44.75px;" width="505">土木建筑工程施工其他学科</td>
    <td class="et11" style="width: 77.55pt; height: 44.75px;" width="155">&nbsp;</td>
    </tr>
    <tr style="height: 46.4375px;">
    <td class="et10" style="height: 46.4375px; width: 49.55pt;" width="99" height="45">41</td>
    <td class="et10" style="width: 59.55pt; height: 46.4375px;" width="119">何<span class="font3">&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="font5">鹏</span></td>
    <td class="et10" style="width: 177.55pt; height: 46.4375px;" width="355">兰州铁路局集团有限公司</td>
    <td class="et10" style="width: 213.05pt; height: 46.4375px;" width="426">总工程师</td>
    <td class="et10" style="width: 87.55pt; height: 46.4375px;" width="175">正高级工程师</td>
    <td class="et10" style="width: 252.55pt; height: 46.4375px;" width="505">土木工程</td>
    <td class="et11" style="width: 77.55pt; height: 46.4375px;" width="155">&nbsp;</td>
    </tr>
    </tbody>
    </table>
    `
  },
  zscqJsbzGj: {
    content: `
    <table style="border-collapse: collapse; width: 100%; height: 319.75px;" border="1" width="921" cellspacing="0" cellpadding="0"><colgroup><col style="width: 128px;" width="67" /><col style="width: 218px;" width="202" /><col style="width: 347px;" width="396" /><col style="width: 229px;" width="218" /></colgroup>
<tbody>
<tr style="height: 52.9688px;">
<td class="et4" style="height: 52.9688px; width: 33.7pt;" width="67" height="100"><strong>序号</strong></td>
<td class="et4" style="width: 101.05pt; height: 52.9688px;" width="202"><strong>标准编号</strong></td>
<td class="et4" style="width: 198pt; height: 52.9688px;" width="396"><strong>标准名称</strong></td>
<td class="et4" style="width: 109.3pt; height: 52.9688px;" width="218"><strong>主编单位</strong></td>
</tr>
<tr style="height: 40.9688px;">
<td class="et4" style="height: 40.9688px; width: 33.7pt;" width="67" height="60">&nbsp;</td>
<td class="et4" style="width: 101.05pt; height: 40.9688px;" width="202">&nbsp;</td>
<td class="et4" style="width: 198pt; height: 40.9688px;" width="396">&nbsp;</td>
<td class="et4" style="width: 109.3pt; height: 40.9688px;" width="218">&nbsp;</td>
</tr>
<tr style="height: 38.9688px;">
<td class="et4" style="height: 38.9688px; width: 33.7pt;" width="67" height="60">&nbsp;</td>
<td class="et4" style="width: 101.05pt; height: 38.9688px;" width="202">&nbsp;</td>
<td class="et4" style="width: 198pt; height: 38.9688px;" width="396">&nbsp;</td>
<td class="et4" style="width: 109.3pt; height: 38.9688px;" width="218">&nbsp;</td>
</tr>
<tr style="height: 37.9688px;">
<td class="et4" style="height: 37.9688px; width: 33.7pt;" width="67" height="60">&nbsp;</td>
<td class="et4" style="width: 101.05pt; height: 37.9688px;" width="202">&nbsp;</td>
<td class="et4" style="width: 198pt; height: 37.9688px;" width="396">&nbsp;</td>
<td class="et4" style="width: 109.3pt; height: 37.9688px;" width="218">&nbsp;</td>
</tr>
<tr style="height: 38.9688px;">
<td class="et4" style="height: 38.9688px; width: 33.7pt;" width="67" height="60">&nbsp;</td>
<td class="et4" style="width: 101.05pt; height: 38.9688px;" width="202">&nbsp;</td>
<td class="et4" style="width: 198pt; height: 38.9688px;" width="396">&nbsp;</td>
<td class="et4" style="width: 109.3pt; height: 38.9688px;" width="218">&nbsp;</td>
</tr>
<tr style="height: 39.9688px;">
<td class="et4" style="height: 39.9688px; width: 33.7pt;" width="67" height="60">&nbsp;</td>
<td class="et4" style="width: 101.05pt; height: 39.9688px;" width="202">&nbsp;</td>
<td class="et4" style="width: 198pt; height: 39.9688px;" width="396">&nbsp;</td>
<td class="et4" style="width: 109.3pt; height: 39.9688px;" width="218">&nbsp;</td>
</tr>
<tr style="height: 35.9688px;">
<td class="et4" style="height: 35.9688px; width: 33.7pt;" width="67" height="60">&nbsp;</td>
<td class="et4" style="width: 101.05pt; height: 35.9688px;" width="202">&nbsp;</td>
<td class="et4" style="width: 198pt; height: 35.9688px;" width="396">&nbsp;</td>
<td class="et4" style="width: 109.3pt; height: 35.9688px;" width="218">&nbsp;</td>
</tr>
<tr style="height: 33.9688px;">
<td class="et4" style="height: 33.9688px; width: 33.7pt;" width="67" height="60">&nbsp;</td>
<td class="et4" style="width: 101.05pt; height: 33.9688px;" width="202">&nbsp;</td>
<td class="et4" style="width: 198pt; height: 33.9688px;" width="396">&nbsp;</td>
<td class="et4" style="width: 109.3pt; height: 33.9688px;" width="218">&nbsp;</td>
</tr>
</tbody>
</table>
`
  }
}
