<template>
  <div class="technologyList">
    <div class="technologyListWrap">
      <div class="technologyListInner" v-for="(item, index) in newsList" :key="index">
        <div class="fl"></div>
        <div class="fr">{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</div>
        <div class="inner">
          <a :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`" target="_blank">{{ item.title }}</a>
        </div>
      </div>
    </div>
    <div class="pagin" style="text-align: center;">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        :pager-count="6"
        layout="sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { articles } from '@/api/common'
export default {
  props: {
    paramsName: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      content: '',
      newsList: [
        // {
        //   publishDate: '2022-02-02',
        //   title: '上课的话v地方就把对方都被迫的付款v怕部分代表迫使v发'
        // }
      ],
      pageSize: 20,
      pageNum: 1,
      total: 0,
    }
  },
  mounted() {
    var _this = this
    this.$nextTick(() => {
      _this.articles()
    })
  },
  methods: {
    handleCurrentChange(val) {
      var _this = this
      _this.pageNum = val
      _this.articles()
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      var _this = this
      _this.pageSize = val
      _this.articles()
    },
    articles() {
      var _this = this

      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: _this.paramsName,
        pageNum: _this.pageNum,
        pageSize: _this.pageSize
      }
      articles(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsList = data.rows
          _this.total = data.totalRows
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.technologyListInner {
  height: 40px;
  line-height: 40px;
  &::after {
    display: block;
    width: 0;
    height: 0;
    content: "";
    clear: both;
  }
  .fl {
    float: left;
    width: 3px;
    height: 3px;
    background-color: #333;
    border-radius: 50%;
    margin: 19px 12px 0 0;
  }
  .fr {
    float: right;
    margin-left: 20px;
  }
  .inner {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
.techologyRightBox .pagin {
  margin-top: 20px;
}
@media (max-width: 768px) {
  .technologyListInner .fr {
    margin-left: 10px;
  }
  .techologyRightBox .pagin {
    margin-top: 10px;
  }
}
</style>