<template>
  <div class="technologyTable">
    <!-- <div class="technologyTableHd" v-if="listChild.length">
      <div
        class="fl technologyTableHdInner"
        :class="{
          active: activeHd == index,
          technologyTableHdInner02: listChild.length == 2,
          technologyTableHdInner03: listChild.length == 3,
          technologyTableHdInner04: listChild.length == 4,
          technologyTableHdInner05: listChild.length == 5
        }"
        v-for="(item, index) in listChild"
        :key="index"
        @click="handleTableHd(index)"
      >
        <div class="inner">
          {{ item.name }}
        </div>
      </div>
    </div> -->
    <div class="technologyTableWrap" v-html="content">
      <!-- <el-table :data="newsList" size="medium" style="width: 100%">
        <el-table-column type="index" width="70" align="center" label="序号"></el-table-column>
        <el-table-column
          :prop="item.prop"
          :label="item.name"
          align="center"
          v-for="(item, index) in tableHd"
          :key="index"
        ></el-table-column>
      </el-table> -->
    </div>
  </div>
</template>
<script>
import {listData} from '@/api/data/listTable.js'
export default {
  props: {
    paramsName: {
      default: '',
      type: String
    },
    listChild: {
      default() {
        return []
      },
      type: Array
    }
  },
  data() {
    return {
      activeHd: 0,
      content: `<table style="border-collapse: collapse; width: 100%; height: 319.75px;" border="1" width="921" cellspacing="0" cellpadding="0"><colgroup><col style="width: 128px;" width="67" /><col style="width: 218px;" width="202" /><col style="width: 347px;" width="396" /><col style="width: 229px;" width="218" /></colgroup>
<tbody>
<tr style="height: 52.9688px;">
<td class="et4" style="height: 52.9688px; width: 33.7pt;" width="67" height="100"><strong>序号</strong></td>
<td class="et4" style="width: 101.05pt; height: 52.9688px;" width="202"><strong>标准编号</strong></td>
<td class="et4" style="width: 198pt; height: 52.9688px;" width="396"><strong>标准名称</strong></td>
<td class="et4" style="width: 109.3pt; height: 52.9688px;" width="218"><strong>主编单位</strong></td>
</tr>
<tr style="height: 40.9688px;">
<td class="et4" style="height: 40.9688px; width: 33.7pt;" width="67" height="60">&nbsp;</td>
<td class="et4" style="width: 101.05pt; height: 40.9688px;" width="202">&nbsp;</td>
<td class="et4" style="width: 198pt; height: 40.9688px;" width="396">&nbsp;</td>
<td class="et4" style="width: 109.3pt; height: 40.9688px;" width="218">&nbsp;</td>
</tr>
<tr style="height: 38.9688px;">
<td class="et4" style="height: 38.9688px; width: 33.7pt;" width="67" height="60">&nbsp;</td>
<td class="et4" style="width: 101.05pt; height: 38.9688px;" width="202">&nbsp;</td>
<td class="et4" style="width: 198pt; height: 38.9688px;" width="396">&nbsp;</td>
<td class="et4" style="width: 109.3pt; height: 38.9688px;" width="218">&nbsp;</td>
</tr>
<tr style="height: 37.9688px;">
<td class="et4" style="height: 37.9688px; width: 33.7pt;" width="67" height="60">&nbsp;</td>
<td class="et4" style="width: 101.05pt; height: 37.9688px;" width="202">&nbsp;</td>
<td class="et4" style="width: 198pt; height: 37.9688px;" width="396">&nbsp;</td>
<td class="et4" style="width: 109.3pt; height: 37.9688px;" width="218">&nbsp;</td>
</tr>
<tr style="height: 38.9688px;">
<td class="et4" style="height: 38.9688px; width: 33.7pt;" width="67" height="60">&nbsp;</td>
<td class="et4" style="width: 101.05pt; height: 38.9688px;" width="202">&nbsp;</td>
<td class="et4" style="width: 198pt; height: 38.9688px;" width="396">&nbsp;</td>
<td class="et4" style="width: 109.3pt; height: 38.9688px;" width="218">&nbsp;</td>
</tr>
<tr style="height: 39.9688px;">
<td class="et4" style="height: 39.9688px; width: 33.7pt;" width="67" height="60">&nbsp;</td>
<td class="et4" style="width: 101.05pt; height: 39.9688px;" width="202">&nbsp;</td>
<td class="et4" style="width: 198pt; height: 39.9688px;" width="396">&nbsp;</td>
<td class="et4" style="width: 109.3pt; height: 39.9688px;" width="218">&nbsp;</td>
</tr>
<tr style="height: 35.9688px;">
<td class="et4" style="height: 35.9688px; width: 33.7pt;" width="67" height="60">&nbsp;</td>
<td class="et4" style="width: 101.05pt; height: 35.9688px;" width="202">&nbsp;</td>
<td class="et4" style="width: 198pt; height: 35.9688px;" width="396">&nbsp;</td>
<td class="et4" style="width: 109.3pt; height: 35.9688px;" width="218">&nbsp;</td>
</tr>
<tr style="height: 33.9688px;">
<td class="et4" style="height: 33.9688px; width: 33.7pt;" width="67" height="60">&nbsp;</td>
<td class="et4" style="width: 101.05pt; height: 33.9688px;" width="202">&nbsp;</td>
<td class="et4" style="width: 198pt; height: 33.9688px;" width="396">&nbsp;</td>
<td class="et4" style="width: 109.3pt; height: 33.9688px;" width="218">&nbsp;</td>
</tr>
</tbody>
</table>`,
      hdList: [
        {
          name: '国家标准',
          id: '01'
        },
        {
          name: '行业标准',
          id: '02'
        },
        {
          name: '地方标准',
          id: '03'
        },
        {
          name: '团体标准',
          id: '04'
        },
        {
          name: '甘肃建投系列标准',
          id: '05'
        }
      ],
      tableHd: [
        {
          name: '标准编号',
          prop: 'code'
        },
        {
          name: '标准名称',
          prop: 'name'
        },
        {
          name: '主编单位',
          prop: 'unit'
        }
      ],
      newsList: [
        {
          code: 'GB50688-2011',
          name: '城市道路交通设施设计规范',
          unit: '智能建筑工程有限公司'
        },
        {
          code: 'GB50688-2011',
          name: '城市道路交通设施设计规范',
          unit: '智能建筑工程有限公司'
        },
        {
          code: 'GB50688-2011',
          name: '城市道路交通设施设计规范',
          unit: '智能建筑工程有限公司'
        },
        {
          code: 'GB50688-2011',
          name: '城市道路交通设施设计规范',
          unit: '智能建筑工程有限公司'
        },
        {
          code: 'GB50688-2011',
          name: '城市道路交通设施设计规范',
          unit: '智能建筑工程有限公司'
        },
        {
          code: 'GB50688-2011',
          name: '城市道路交通设施设计规范',
          unit: '智能建筑工程有限公司'
        },
        {
          code: 'GB50688-2011',
          name: '城市道路交通设施设计规范',
          unit: '智能建筑工程有限公司'
        }
      ]
    }
  },
  mounted() {
    // console.log('listChild :>> ', this.listChild)
    // this.title = listData[this.paramsName].title
    console.log('paramsName :>> ', this.paramsName);
    if (listData[this.paramsName]) {
      this.content = listData[this.paramsName].content
    } else {
      this.content = this.$options.data().content
    }
    
  },
  methods: {
    handleTableHd(index) {
      var _this = this

      _this.activeHd = index
    }
  }
}
</script>
<style lang="scss">
.technologyTableHd {
  margin-bottom: 10px;
  &::after {
    display: block;
    width: 0;
    height: 0;
    content: '';
    clear: both;
    overflow: hidden;
  }
}
.technologyTableHdInner {
  float: left;
  width: 20%;
  height: 38px;
  line-height: 38px;
  text-align: center;
  cursor: pointer;
  .inner {
    height: 100%;
    color: #fff;
    font-weight: bold;
    background-color: #6d767d;
    border-left: 1px solid #fff;
    -moz-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    &:hover {
      background-color: #0263b2;
    }
  }
  &:first-child .inner {
    border-left: none;
  }
  &.active .inner {
    background-color: #0263b2;
  }
}
.technologyTableHdInner02 {
  width: 50%;
}
.technologyTableHdInner03 {
  width: 33.33%;
}
.technologyTableHdInner04 {
  width: 25%;
}
.technologyTableHdInner05 {
  width: 20%;
}
.technologyTableWrap {
  .el-table th.el-table__cell {
    background-color: #e0e0e8;
    padding: 6px 0;
    border-left: 1px solid #fff;
    color: #0263b2;
  }
}
</style>
