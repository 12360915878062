<template>
  <div class="technologyPic">
    <el-row :gutter="15" class="technologyList" v-for="(item, index) in newsList" :key="index">
      <el-col :span="6">
        <div class="listImg">
          <img :src="item.thumb1" alt="">
        </div>
      </el-col>
      <el-col :span="18">
        <div class="listCont">
          <div class="tit">{{item.title}}</div>
          <div class="des">{{item.artDesc}}</div>
        </div>
      </el-col>
    </el-row>

    <div class="pagin technologyPagin">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        :pager-count="11"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pageSize: 10,
      pageNum: 1,
      total: 0,
      content: '',
      newsList: [
        {
          title: '页可明',
          url: '',
          thumb1: require('@/assets/image/flashimg2.jpg'),
          artDesc: '长期从事土木建筑施工技术、施工工艺的研究与实践。针对“高、大、深、重、新”不同对象形成了不同的施工技术体系。例如考虑环境与工艺效应的深基坑支护技术体系;超高层建筑和高耸构筑物的模板脚手架组合技术体系;特殊结构工程综合吊装技术体系。“南浦大桥建设',
          content: ''
        },
        {
          title: '页可明',
          url: '',
          thumb1: require('@/assets/image/flashimg2.jpg'),
          artDesc: '长期从事土木建筑施工技术、施工工艺的研究与实践。针对“高、大、深、重、新”不同对象形成了不同的施工技术体系。例如考虑环境与工艺效应的深基坑支护技术体系;超高层建筑和高耸构筑物的模板脚手架组合技术体系;特殊结构工程综合吊装技术体系。“南浦大桥建设...',
          content: ''
        },
        {
          title: '页可明',
          url: '',
          thumb1: require('@/assets/image/flashimg2.jpg'),
          artDesc: '长期从事土木建筑施工技术、施工工艺的研究与实践。针对“高、大、深、重、新”不同对象形成了不同的施工技术体系。例如考虑环境与工艺效应的深基坑支护技术体系;超高层建筑和高耸构筑物的模板脚手架组合技术体系;特殊结构工程综合吊装技术体系。“南浦大桥建设...',
          content: ''
        },
      ]
    }
  },
  mounted() {
    
  },
  methods: {
    handleCurrentChange(val) {
      var _this = this
      _this.pageNum = val
      // _this.articles()
    }
  }
}
</script>
<style lang="scss" scoped>
.technologyList {
  margin: 36px 0;
}
.listImg {
  height: 152px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
.listCont {
  .tit {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  }
  .des {
    margin-top: 10px;
    font-size: 14px;
    line-height: 24px;
  }
}
.technologyPic {
  .listImg {
    width: 132px;
    height: 156px;
    margin: 0 auto;
  }
}
.technologyPagin {
  margin-top: 40px;
  text-align: center;
}
</style>